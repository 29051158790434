import axios, { AxiosError } from 'axios'
import BigNumber from 'bignumber.js'

import { environment } from '../../config/settings'
import { proxyApiFetch } from '../../helpers/ProxyApiFetch'
import { ProxyApiFetchReturn } from '../../helpers/ProxyApiFetch/types'
import { Address, TokenId, TokenNetwork, UserWalletCategory } from '../../model'

export type WalletCategoryResponse = { category: UserWalletCategory }

export type TokenBalanceRecord = {
  token_balance: BigNumber.Value // 62.48450888522142
  usd_balance: BigNumber.Value // 59.51599676736109
  network: TokenNetwork // "polygon"
  token_id: TokenId // "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270-polygon"
}

export type WalletTokensRecord = Record<Address, TokenBalanceRecord>

export type BalanceData = {
  wallet_address: Address
  tokens: WalletTokensRecord
}

class WalletsApi {
  async getWalletCategory(account: Address): Promise<ProxyApiFetchReturn<WalletCategoryResponse>> {
    return proxyApiFetch<WalletCategoryResponse>(
      environment.getDexGuruAPIV3Url(),
      `/wallets/${account}/category`,
      {
        init: { method: 'GET' },
      }
    )
  }
  // https://api-stage.dex.guru/#/Trader%20profile/get_trader_balances_v2_wallets__wallet_address__balance_get
  async fetchBalance({ account }: { account: Address }): Promise<ProxyApiFetchReturn<BalanceData>> {
    const url = `${environment.getDexGuruAPIV2Url()}/wallets/${account}/balance`
    try {
      const response = await axios.get<BalanceData>(url)

      return {
        responseData: response.data,
      }
    } catch (error) {
      return {
        error: {
          message: 'response to fail',
          status: (error as AxiosError<BalanceData>).response?.status,
        },
      }
    }
  }
}

export default WalletsApi
