import { environment } from '../../config/settings'
import { proxyApiFetch } from '../../helpers/ProxyApiFetch'
import { ProxyApiFetchReturn } from '../../helpers/ProxyApiFetch/types'
import { isValidTokenId } from '../../helpers/validators'
import {
  AMMModel,
  ChartRowCategoryV3,
  ChartRowV3,
  PeriodChartType,
  TokenId,
  TokenV3,
  TraderBalanceChartData,
} from '../../model'

type LoadTokenHistoryData = {
  total: number
  data: ChartRowV3[]
}

type LoadTokenHistoryCategoryData = {
  total: number
  data: ChartRowCategoryV3[]
}
type LoadLastAMMsData = {
  total: number
  data: AMMModel[]
}

type LoadTokensData = {
  total: number
  data: TokenV3[]
}

type HoldersChartData = {
  tokenID: string
  walletAddress: string
  data: Array<Pick<TraderBalanceChartData, 'timestamp' | 'value' | 'price'>>
}

class TokensApi {
  async loadTokenHistory({
    currentTokenId,
    beginTimestamp,
    endTimestamp,
    currency,
    amms,
    interval,
  }: {
    currentTokenId: TokenId
    beginTimestamp: number
    endTimestamp: number
    currency: string
    amms: string
    interval?: number
  }): Promise<ProxyApiFetchReturn<LoadTokenHistoryData>> {
    const pathInterval = interval ? `&interval=${interval}` : ``
    const url = `/tokens/${currentTokenId}/history?begin_timestamp=${beginTimestamp}&end_timestamp=${endTimestamp}&currency=${currency}&amms=${amms}${pathInterval}`

    const response = await proxyApiFetch<LoadTokenHistoryData>(
      environment.getDexGuruAPIV3Url(),
      url
    )

    return response
  }

  async loadTokenHistoryByTraderCategory({
    currentTokenId,
    beginTimestamp,
    endTimestamp,
    currency,
    interval,
  }: {
    currentTokenId: TokenId
    beginTimestamp: number
    endTimestamp: number
    currency: string
    interval?: number
  }): Promise<ProxyApiFetchReturn<LoadTokenHistoryCategoryData>> {
    const pathInterval = interval ? `&interval=${interval}` : ``
    const url = `/tokens/${currentTokenId}/history/categories/volume?begin_timestamp=${beginTimestamp}&end_timestamp=${endTimestamp}&currency=${currency}${pathInterval}`

    const response = await proxyApiFetch<LoadTokenHistoryCategoryData>(
      environment.getDexGuruAPIV3Url(),
      url
    )

    return response
  }

  async loadLastAMMs(tokenId?: TokenId): Promise<ProxyApiFetchReturn<LoadLastAMMsData>> {
    if (!isValidTokenId(tokenId)) {
      return {
        error: {
          message: `invalid token id, ${tokenId}`,
        },
      }
    }

    const url = `/tokens/${tokenId}/last`

    const response = await proxyApiFetch<LoadLastAMMsData>(environment.getDexGuruAPIV2Url(), url)

    return response
  }

  async loadList({
    tokenIds,
    availableNetworks,
  }: {
    tokenIds: string[]
    availableNetworks: string // eth,bsc,...
  }): Promise<ProxyApiFetchReturn<LoadTokensData>> {
    const url = `/tokens`
    return await proxyApiFetch<LoadTokensData>(environment.getDexGuruAPIV3Url(), url, {
      init: { method: 'POST', body: JSON.stringify({ ids: tokenIds, network: availableNetworks }) },
    })
  }

  async getTokenHoldersChart({
    tokenId,
    walletAddress,
    period,
    hasGetPrice,
  }: {
    tokenId: TokenId
    walletAddress: string
    period: PeriodChartType
    hasGetPrice?: boolean
  }): Promise<ProxyApiFetchReturn<HoldersChartData>> {
    const priceSuffix = hasGetPrice ? '' : '&no_price=true'

    const url = `/tokens/${tokenId}/wallets/${walletAddress}/chart?period=${period}${priceSuffix}`

    const response = await proxyApiFetch<HoldersChartData>(environment.getDexGuruAPIV1Url(), url, {
      init: { method: 'GET' },
    })

    return response
  }
}

export default TokensApi
