import axios, { AxiosError } from 'axios'

import { environment } from '../../config/settings'
import { ProxyApiFetchReturn } from '../../helpers/ProxyApiFetch/types'
import { FilterOptions, TransactionModel, TransactionModelV2 } from '../../model'

export type LoadTransactionsV2Data = {
  total: number
  data: TransactionModelV2[]
}

export type LoadTransactionsData = {
  total: number
  data: TransactionModel[]
}

class TransactionsApi {
  async load({
    filters,
    useLastEndpoint,
  }: {
    filters: FilterOptions
    useLastEndpoint?: boolean
  }): Promise<ProxyApiFetchReturn<LoadTransactionsData>> {
    try {
      // https://api-stage.dex.guru/#/Tokens%20V3/get_transactions_universal_v3_tokens_transactions_post
      // https://api-stage.dex.guru/#/Tokens%20V3/get_last_transactions_v3_tokens_transactions_last_post
      const path = useLastEndpoint ? '/tokens/transactions/last' : '/tokens/transactions'
      const url = `${environment.getDexGuruAPIV3Url()}${path}`

      const response = await axios.post<LoadTransactionsData>(url, filters)

      return {
        responseData: response.data,
      }
    } catch (error) {
      return {
        error: {
          message: 'response to fail',
          status: (error as AxiosError<LoadTransactionsData>).response?.status,
        },
      }
    }
  }
}

export default TransactionsApi
